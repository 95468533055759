/* <!--abb-whitelisted--> */
export const getModalParams = (url: string | undefined) => {
  let modalUrlHref = '';
  let selfUrl = window.location.href;
  let arr = selfUrl.split('/');
  let selfUrlName = arr[0] + '//' + arr[2] + '/';
  if (typeof url === 'string') {
    if (!url.includes(selfUrlName)) {
      // if url without http and localhost
      if (!url.includes('http://')) {
        url = selfUrlName + url;
      }
    }
    const modalUrl = new URL(url);
    if (url.includes('?')) {
      modalUrlHref = modalUrl.href + '&kiosk';
    } else {
      modalUrlHref = modalUrl.href + '?kiosk';
    }
    return modalUrlHref;
  }
  return selfUrlName + '?kisok';
};

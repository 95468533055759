/* <!--abb-whitelisted--> */
import React, { SyntheticEvent } from 'react';

// import { selectors } from '@grafana/e2e-selectors';
import { /*Tooltip,*/ Form, Field, VerticalGroup, Button } from '@grafana/ui';

// import { submitButton } from '../Login/LoginForm';
import { legendStyles } from '../Login/LoginForm';
import { PasswordField } from '../PasswordField/PasswordField';
interface Props {
  onSubmit: (pw: string) => void;
  onSkip?: (event?: SyntheticEvent) => void;
}

interface PasswordDTO {
  newPassword: string;
  confirmNew: string;
}

export const ChangePassword = ({ onSubmit, onSkip }: Props) => {
  const submit = (passwords: PasswordDTO) => {
    onSubmit(passwords.newPassword);
  };

  history.pushState(null, location.href);
  window.onpopstate = function () {
    history.go(1);
  }
  const checkstrengthpassword = (passtext: any) => {
    let strongPassword = new RegExp('(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[^A-Za-z0-9])(?=.{8,})');
    if (strongPassword.test(passtext)) {
      return true;
    }
    return false;
  }
  return (
    <Form onSubmit={submit}>
      {({ errors, register, getValues }) => (
          <>
          <ul>
            <h6 className={legendStyles}>Note: <br />
              At least 8 characters that includes a mixture of :<br />
              &bull; Uppercase, lowercase letters and numbers<br />
              &bull; Atleast a Special character  e.g., ! @ # ?
            </h6>
          </ul>
          <Field label="New password" neutral="true" invalid={!!errors.newPassword} error={errors?.newPassword?.message}>
            <PasswordField
              id="new-password"
              autoFocus
              autoComplete="new-password"
              {...register('newPassword', { required: 'New Password is required', validate: (v: string) => checkstrengthpassword(v) === true || 'Password donot match above criteria!', })}
            />
          </Field>
          <Field label="Confirm new password" neutral="true" invalid={!!errors.confirmNew} error={errors?.confirmNew?.message}>
            <PasswordField
              id="confirm-new-password"
              autoComplete="new-password"
              {...register('confirmNew', {
                required: 'Confirmed Password is required',
                validate: (v: string) => v === getValues().newPassword || 'Passwords must match!',
              })}
            />
          </Field>
          <VerticalGroup>
            <Button type="submit" variant="primary-login">
              Submit
            </Button>
{/*}
            {onSkip && (
              <Tooltip
                content="If you skip you will be prompted to change password next time you log in."
                placement="bottom"
              >
                <Button fill="text" onClick={onSkip} type="button" aria-label={selectors.pages.Login.skip}>
                  Skip
                </Button>
              </Tooltip>
            )}
{*/}
          </VerticalGroup>
        </>
      )}
    </Form>
  );
};

/* <!--abb-whitelisted--> */
import { css } from '@emotion/css';
import React, { CSSProperties, useState } from 'react';

import { LinkModel } from '@grafana/data';
import { ModalWindow } from '@grafana/ui/src/components/Modal/ModalWindow';
import { getModalParams } from '@grafana/ui/src/components/Modal/ModalParams';
import { selectors } from '@grafana/e2e-selectors';

import { linkModelToContextMenuItems } from '../../utils/dataLinks';
import { WithContextMenu } from '../ContextMenu/WithContextMenu';
import { MenuGroup, MenuItemsGroup } from '../Menu/MenuGroup';
import { MenuItem } from '../Menu/MenuItem';

export interface DataLinksContextMenuProps {
  children: (props: DataLinksContextMenuApi) => JSX.Element;
  links: () => LinkModel[];
  style?: CSSProperties;
}
export interface State {
  isNew?: boolean;
  editIndex?: number | null;
  modalHref: string;
  modalTitle: string;
  modalTargetOptions: boolean;
}
/* <!--abb-whitelisted-end--> */
export interface DataLinksContextMenuApi {
  openMenu?: React.MouseEventHandler<HTMLOrSVGElement>;
  targetClassName?: string;
}

export const DataLinksContextMenu = ({ children, links, style }: DataLinksContextMenuProps) => {
  const [isNew, setisNew] = useState<boolean>(false);
  const [editIndex, seteditIndex] = useState<number | null>(null);
  const [modalHref, setmodalHref] = useState<string>('');
  const [modalTitle, setmodalTitle] = useState<string>('');
  const [modalTargetOptions, setmodalTargetOptions] = useState<boolean>(false);
  /* <!--abb-whitelisted-end--> */
  const itemsGroup: MenuItemsGroup[] = [{ items: linkModelToContextMenuItems(links), label: 'Data links' }];
  const linksCounter = itemsGroup[0].items.length;
  const renderMenuGroupItems = () => {
    return itemsGroup.map((group, index) => (
      <MenuGroup key={`${group.label}${index}`} label={group.label} ariaLabel={group.label}>
        {(group.items || []).map((item) => (
          <MenuItem
            key={item.label}
            url={item.url}
            label={item.label}
            ariaLabel={item.label}
            target={item.target}
            icon={item.icon}
            active={item.active}
            onClick={item.onClick}
            onShowLink={onShowLink}
            targetOptions={item.targetOptions}
          />
        ))}
      </MenuGroup>
    ));
  };

  // Use this class name (exposed via render prop) to add context menu indicator to the click target of the visualization
  const targetClassName = css`
    cursor: context-menu;
  `;
  const onShowLink = (linkHref: any, linkTitle: any, linkTargetOptions: boolean) => {
    seteditIndex(1);
    setisNew(true);
    setmodalHref(getModalParams(linkHref));
    setmodalTitle(linkTitle);
    setmodalTargetOptions(linkTargetOptions);
  };

  const onDataLinkCancel = (index: number) => {
    if (isNew) {
      setisNew(false);
    }
    seteditIndex(null);
  };

  const linkRender = () => {
    const linkModel: any = links()[0];
    var regE = /(from=|to=)((\d+)(\+|\-)(\d+))/; var cellLink: any | undefined = '';
    cellLink = linkModel.href ? linkModel.href : '';
    var count = 0;
    while (null != cellLink.match(regE) && 3 > count) {
      cellLink = cellLink.replace(cellLink.match(regE)[2], Function('"use strict";return ' + cellLink.match(regE)[2]));
      count++;
    }
    return linkModel.targetOptions ? (
      <>
        {editIndex !== null && modalTargetOptions ? (
          <ModalWindow
            title={modalTitle}
            isOpen={isNew}
            onDismiss={() => {
              onDataLinkCancel(1);
            }}
          >
            <iframe className="iframe" src={modalHref} />
          </ModalWindow>
        ) : (
          <a
            onClick={() => onShowLink(cellLink, linkModel.title, linkModel.targetOptions)}
            title={linkModel.title}
            style={{ display: 'flex' }}
            aria-label={selectors.components.DataLinksContextMenu.singleLink}
          >
            {children({})}
          </a>
        )}
      </>
    ) : (
      <a
        href={cellLink}
        onClick={linkModel.onClick}
        target={linkModel.target}
        title={linkModel.title}
        style={{ ...style, overflow: 'hidden', display: 'flex' }}
        aria-label={selectors.components.DataLinksContextMenu.singleLink}
      >
        {children({})}
      </a>
    );
  };
  
  if (linksCounter > 1) {
    return (
      <>
        <WithContextMenu renderMenuItems={renderMenuGroupItems}>
          {({ openMenu }) => {
            return children({ openMenu, targetClassName });
          }}
        </WithContextMenu> {editIndex !== null && (
          <ModalWindow
            title={modalTitle}
            isOpen={isNew}
            onDismiss={() => {
              onDataLinkCancel(1);
            }}
          >
            <iframe className="iframe" src={getModalParams(modalHref)} scrolling="no" allowFullScreen />
          </ModalWindow>
        )}
      </>
    );
  } else {
    return linkRender();
  }
  /* <!--abb-whitelisted-end--> */
};

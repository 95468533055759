/* <!--abb-whitelisted--> */
import { css, cx } from '@emotion/css';
import React from 'react';
import SVG from 'react-inlinesvg';

import { GrafanaTheme2, isIconName } from '@grafana/data';

import { Icon as ABBIcon} from '../../../../../public/abb/@abb/abb-common-ux-react';
import {constABBIcon} from  '../../../../../public/abb/Constants';

import { useStyles2 } from '../../themes/ThemeContext';
import { IconName, IconType, IconSize } from '../../types/icon';

import { cacheInitialized, initIconCache, iconRoot } from './iconBundle';
import { getIconSubDir, getSvgSize } from './utils';

export interface IconProps extends React.HTMLAttributes<HTMLDivElement> {
  name: IconName;
  size?: IconSize;
  type?: IconType;
  title?: string;
}

const getIconStyles = (theme: GrafanaTheme2) => {
  return {
    // line-height: 0; is needed for correct icon alignment in Safari
    container: css`
      label: Icon;
      display: inline-block;
      line-height: 0;
    `,
    icon: css`
      vertical-align: middle;
      display: inline-block;
      fill: currentColor;
    `,
    orange: css`
      fill: ${theme.v1.palette.abbYellow};
    `,
  };
};

export const Icon = React.forwardRef<HTMLDivElement, IconProps>(
  ({ size = 'md', type = 'default', name, className, style, title = '', ...divElementProps }, ref) => {
    const styles = useStyles2(getIconStyles);

    /* Temporary solution to display also font awesome icons */
    if (name?.startsWith('fa fa-')) {
      return <i className={getFontAwesomeIconStyles(name, className)} {...divElementProps} style={style} />;
    } 
    if (name?.startsWith('abb/')) {
    /* Temporary solution to display also font awesome icons */    
      return <ABBIcon name={name} />;
    }
    
    switch(name) {
/*      case 'home-alt':
        return <ABBIcon name={constABBIcon.home} sizeClass="small" />;
      break;
      case 'star':
        return <ABBIcon name={constABBIcon.star} sizeClass="small" />;
      break;
*/     case 'favorite':
        return <ABBIcon name={constABBIcon.star} sizeClass="small" color="#FFD800" />;
      break;
/*      case 'apps':
        return <ABBIcon name={constABBIcon.pause} sizeClass="small" />;
      break;
      case 'adjust-circle':
        return <ABBIcon name={constABBIcon.wrench} sizeClass="small" />;
      break;
      case 'settings':
        return <ABBIcon name={constABBIcon.settings} sizeClass="small" />;
      break;
      case 'cog':
        return <ABBIcon name={constABBIcon.settings} sizeClass="small" />;
      break;
      case 'explore':
        return <ABBIcon name={constABBIcon.explore} sizeClass="small" />;
      break;
      case 'briefcase':
        return <ABBIcon name={constABBIcon.briefcase} sizeClass="small" />;
      break;
*/    case 'check-square':
        return <ABBIcon name={constABBIcon.checkmark} sizeClass="small" />;
      break;
/*    case 'repeat':
        return <ABBIcon name={constABBIcon.hourglass} sizeClass="small" />;
      break;
      case 'search':
        return <ABBIcon name={constABBIcon.search} sizeClass="small" />;
      break;
      case 'plus':
        return <ABBIcon name={constABBIcon.plus} sizeClass="small" />;
      break;
      case 'minus':
        return <ABBIcon name={constABBIcon.minus} sizeClass="small" />;
      break;
      case 'panel-add':
        return <ABBIcon name={constABBIcon.barchart} sizeClass="large" />;
      break;
*/     case 'dashboard_fav':
        name = 'star';
      break;

    }
    
    if (!cacheInitialized) {
      initIconCache();
    }
    if (!isIconName(name)) {
      console.warn('Icon component passed an invalid icon name', name);
    }
    if (!name || name.includes('..')) {
      return <div ref={ref}>invalid icon name</div>;
    }

    const svgSize = getSvgSize(size);
    const svgHgt = svgSize;
    const svgWid = name.startsWith('gf-bar-align') ? 16 : name.startsWith('gf-interp') ? 30 : svgSize;
    const subDir = getIconSubDir(name, type);
    const svgPath = `${iconRoot}${subDir}/${name}.svg`;

    return (
      <div className={styles.container} {...divElementProps} ref={ref}>
        <SVG
          src={svgPath}
          width={svgWid}
          height={svgHgt}
          title={title}
          className={cx(styles.icon, className, type === 'mono' ? { [styles.orange]: name === 'favorite' } : '')}
          style={style}
        />
      </div>
    );
  }
);

Icon.displayName = 'Icon';

function getFontAwesomeIconStyles(iconName: string, className?: string): string {
  return cx(
    iconName,
    {
      'fa-spin': iconName === 'fa fa-spinner',
    },
    className
  );
}

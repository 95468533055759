/* <!--abb-whitelisted--> */
export const defaultReportLogo = '/public/img/grafana_icon.svg';
export const defaultEmailLogo = '/public/img/grafana_icon.svg';
/* <!--abb-whitelisted-starts--> */
export const ABBlogo='https://1000logos.net/wp-content/uploads/2021/05/ABB-logo.png';
export const ABBUrl='http://www.abb.com';
export const BASE_URL = '/reports';
export const API_ROOT = 'api/reports/images/';

export const defaultScaleFactor = 2;
export const reportScales: Record<number, { style?: React.CSSProperties; label: string }> = {
  1: { label: 'Zoom in', style: { transform: 'translateX(-10%)' } },
  2: { label: 'Standard' },
  3: { label: 'Zoom out', style: { width: '100%', left: '91%' } },
};

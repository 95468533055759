/* <!--abb-whitelisted--> */
import React, { Component } from 'react';

import { renderMarkdown, LinkModelSupplier, ScopedVars } from '@grafana/data';
// import { selectors } from '@grafana/e2e-selectors';
import { locationService, getTemplateSrv } from '@grafana/runtime';
import { Tooltip, PopoverContent } from '@grafana/ui';
import { getTimeSrv, TimeSrv } from 'app/features/dashboard/services/TimeSrv';
import { PanelModel } from 'app/features/dashboard/state/PanelModel';
import { InspectTab } from 'app/features/inspector/types';

/* <!--abb-whitelisted-starts--> */
import { getModalParams } from '../../../../../../packages/grafana-ui/src/components/Modal/ModalParams';
import { ModalWindow } from '../../../../../../packages/grafana-ui/src/components/Modal/ModalWindow';
/* <!--abb-whitelisted-ends-> */

enum InfoMode {
  Error = 'Error',
  Info = 'Info',
  Links = 'Links',
}

export interface Props {
  panel: PanelModel;
  title?: string;
  description?: string;
  scopedVars?: ScopedVars;
  links?: LinkModelSupplier<PanelModel>;
  error?: string;
}

export interface State {
  isNew?: boolean;
  editIndex?: number | null;
  modalHref: string;
  modalTitle: string;
  modalTargetOptions: boolean;
}

export class PanelHeaderCorner extends Component<Props, State> {
  timeSrv: TimeSrv = getTimeSrv();

  constructor(props: Props) {
    super(props);
    this.state = {
      isNew: false,
      editIndex: null,
      modalHref: '',
      modalTitle: '',
      modalTargetOptions: false,
    };
  }
  getInfoMode = () => {
    const { panel, error } = this.props;
    if (error) {
      return InfoMode.Error;
    }
    if (!!panel.description) {
      return InfoMode.Info;
    }

    if (panel.links && panel.links.length) {
      return InfoMode.Links;
    }

    return undefined;
  };


  onShowLink = (linkHref: any, linkTitle: any, linkTargetOptions: boolean) => {
    this.setState({
      editIndex: 1,
      isNew: true,
      modalHref: getModalParams(linkHref),
      modalTitle: linkTitle,
      modalTargetOptions: linkTargetOptions,
    });
  };

  onCancel = (index: number) => {
    if (this.state.isNew) {
      this.setState({
        isNew: false,
      });
    }
    this.setState({
      editIndex: null,
    });
  };

  getInfoContent = (): JSX.Element => {
    const { panel } = this.props;
    const markdown = panel.description || '';
    const interpolatedMarkdown = getTemplateSrv().replace(markdown, panel.scopedVars);
    const markedInterpolatedMarkdown = renderMarkdown(interpolatedMarkdown);
    const links = this.props.links && this.props.links.getLinks(panel.replaceVariables);
    return (
      <div className="panel-info-content markdown-html">
        <div dangerouslySetInnerHTML={{ __html: markedInterpolatedMarkdown }} />

        {links && links.length > 0 && (
          <ul className="panel-info-corner-links">
            {links.map((link, idx) => {
                return (
                <li key={idx}>
                    <a className="panel-info-corner-links__item" href={link.href} target={link.target}>
                      {link.title}
                    </a>
                </li>
              );
            })}
          </ul>
        )}
      </div>
    );
  };

  /**
   * Open the Panel Inspector when we click on an error
   */
  onClickError = () => {
    locationService.partial({
      inspect: this.props.panel.id,
      inspectTab: InspectTab.Error,
    });
  };

  renderCornerType(infoMode: InfoMode, content: PopoverContent, onClick?: () => void) {
    const theme = infoMode === InfoMode.Error ? 'error' : 'info';
    const className = `panel-info-corner panel-info-corner--${infoMode.toLowerCase()}`;
    /* <!--abb-whitelisted-starts--> */
    return (
      <Tooltip content={content} placement="top-start" theme={theme} interactive>
        <section className={className} onClick={onClick} >
          <i aria-hidden className="fa" />
          <span className="panel-info-corner-inner" />
          {this.state.editIndex !== null && this.state.modalTargetOptions && (
            <ModalWindow
              title={this.state.modalTitle}
              isOpen={true}
              onDismiss={() => {
                this.onCancel(1);
              }}
            >
              <iframe title="modalframe" className="iframe" src={this.state.modalHref} />
            </ModalWindow>
          )}
        </section>
      </Tooltip>
    );
    /* <!--abb-whitelisted-end--> */
  }

  render() {
    const { error } = this.props;
    const infoMode: InfoMode | undefined = this.getInfoMode();

    if (!infoMode) {
      return null;
    }

    if (infoMode === InfoMode.Error && error) {
      return this.renderCornerType(infoMode, error, this.onClickError);
    }

    if (infoMode === InfoMode.Info || infoMode === InfoMode.Links) {
      return this.renderCornerType(infoMode, this.getInfoContent);
    }

    return null;
  }
}

export default PanelHeaderCorner;

/* <!--abb-whitelisted--> */
import { css } from '@emotion/css';

import { ABBTheme } from 'abb/ABBConfig';

import React, { ReactElement } from 'react';

import { selectors } from '@grafana/e2e-selectors';
import { Button, Form, Input, Field } from '@grafana/ui';

import { PasswordField } from '../PasswordField/PasswordField';

import { FormModel } from './LoginCtrl';

interface Props {
  children: ReactElement;
  onSubmit: (data: FormModel) => void;
  isLoggingIn: boolean;
  passwordHint: string;
  loginHint: string;
}

const wrapperStyles = css`
  width: 100%;
  padding-bottom: 16px;
`;

export const inputStyles = css`
div{
input{
  color: black;
}
}
font-weight: ${ABBTheme.font.md};
label: input-wrapper;
display: flex;
  color: ${ABBTheme.palette.black};
box-sizing: border-box;
height: 40px;
background-color: ${ABBTheme.palette.white};
  border: 1px solid ${ABBTheme.palette.darkGray3};
&:focus {
  color: ${ABBTheme.palette.black};
  background-color: ${ABBTheme.palette.white};
  border-radius: 1px solid ${ABBTheme.palette.darkGray3};
  }
 `;
export const legendStyles = css`
  color: ${ABBTheme.palette.black1};  

`;
export const submitButton = css`
  justify-content: center;
  // width: 100%;
`;

export const LoginForm = ({ children, onSubmit, isLoggingIn, passwordHint, loginHint }: Props) => {
  return (
    <div className={wrapperStyles}>
      <Form onSubmit={onSubmit} validateOn="onChange">
        {({ register, errors }) => (
          <>
            <Field label="Email or username" neutral="true" invalid={!!errors.user} error={errors.user?.message} >
              <Input className={inputStyles}
                {...register('user', { required: 'Email or username is required' })}
                autoFocus
                autoCapitalize="none"
                placeholder={loginHint}
                aria-label={selectors.pages.Login.username}
              />
            </Field>
            <Field label="Password" neutral="true" invalid={!!errors.password} error={errors.password?.message} >
              <PasswordField 
                id="current-password"
                autoComplete="current-password"
                passwordHint={passwordHint}
                {...register('password', { required: 'Password is required' })}
              />
            </Field>
            <Button
              type="submit"
              variant="primary-login"
              aria-label={selectors.pages.Login.submit}
              className={submitButton}
              disabled={isLoggingIn}
            >
              {isLoggingIn ? 'Signing In...' : 'Sign-In'}
            </Button>
            {children}
          </>
        )}
      </Form>
    </div>
  );
};

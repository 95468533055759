/* <!--abb-whitelisted--> */
const fontFamily = {
  base: 'ABBVoice', 
}

const fontSize = {
  sm: '14px',
  md: '16px',
  lg: '18px',
  vlg: '32px',
}

const margin = {
  sm: '14px',
  md: '16px',
  lg: '18px',
  vlg: '30px',
}


export const DefaultTheme = {
 font: {
    ...fontFamily,
    ...fontSize,
  },
  margin: {
    ...margin,
  },

};

/* <!--abb-whitelisted--> */
import { SelectableValue, TimeOption } from '@grafana/data';
import { constABBShifts, max_no_of_shifts_allowed } from '../../../../../public/abb/Constants';

let shift_start_time_of_day = '';
/*let total_no_of_shifts_in_a_day = 0;
let shift_start_time = 0;
let no_of_hours_per_shift = 0;
*/

let shift_start_time = Number(localStorage.getItem('localShiftStartTime'));
let total_no_of_shifts_in_a_day = Number(localStorage.getItem('localNoOfShiftsPerDay'));
let no_of_hours_per_shift = Number(localStorage.getItem('localNoOfHoursPerShift'));
if (Number(shift_start_time) > 0) {
  shift_start_time_of_day = '+' + shift_start_time + 'h';
}

export const quickOptions: TimeOption[] = [
  { from: 'now-5m', to: 'now', display: 'Last 5 minutes' },
  { from: 'now-15m', to: 'now', display: 'Last 15 minutes' },
  { from: 'now-30m', to: 'now', display: 'Last 30 minutes' },
  { from: 'now-1h', to: 'now', display: 'Last 1 hour' },
  { from: 'now-3h', to: 'now', display: 'Last 3 hours' },
  { from: 'now-6h', to: 'now', display: 'Last 6 hours' },
  { from: 'now-12h', to: 'now', display: 'Last 12 hours' },
  { from: 'now-24h', to: 'now', display: 'Last 24 hours' },
  { from: 'now-2d', to: 'now', display: 'Last 2 days' },
  { from: 'now-7d', to: 'now', display: 'Last 7 days' },
  { from: 'now-30d', to: 'now', display: 'Last 30 days' },
  { from: 'now-90d', to: 'now', display: 'Last 90 days' },
  { from: 'now-6M', to: 'now', display: 'Last 6 months' },
  { from: 'now-1y', to: 'now', display: 'Last 1 year' },
  { from: 'now-2y', to: 'now', display: 'Last 2 years' },
  { from: 'now-5y', to: 'now', display: 'Last 5 years' },
{ from: 'now-1d/d' + shift_start_time_of_day, to: 'now-1d/d' + shift_start_time_of_day, display: 'Yesterday' },
  {
    from: 'now-2d/d' + shift_start_time_of_day,
    to: 'now-2d/d' + shift_start_time_of_day,
    display: 'Day before yesterday',
  },
  { from: 'now-7d/d' + shift_start_time_of_day, to: 'now-7d/d', display: 'This day last week' },
  { from: 'now-1w/w' + shift_start_time_of_day, to: 'now-1w/w', display: 'Previous week' },
  { from: 'now-1M/M' + shift_start_time_of_day, to: 'now-1M/M', display: 'Previous month' },
  // { from: 'now-1y/y', to: 'now-1y/y', display: 'Previous year' },
  // { from: 'now/d', to: 'now/d', display: 'Today' },
  { from: 'now/d' + shift_start_time_of_day, to: 'now/d', display: 'Today so far' },
  // { from: 'now/w', to: 'now/w', display: 'This week' },
  { from: 'now/w' + shift_start_time_of_day, to: 'now/w', display: 'This week so far' },
  { from: 'now/M' + shift_start_time_of_day, to: 'now/M', display: 'This month so far' },
  // { from: 'now/y', to: 'now/y', display: 'This year' },
  // { from: 'now/y', to: 'now', display: 'This year so far' },
  /*  { from: 'now-90d', to: 'now', display: 'Last 90 days' },
  { from: 'now-6M', to: 'now', display: 'Last 6 months' },
  { from: 'now-1y', to: 'now', display: 'Last 1 year' },
  { from: 'now-2y', to: 'now', display: 'Last 2 years' },
  { from: 'now-5y', to: 'now', display: 'Last 5 years' },
*/
];

export const monthOptions: Array<SelectableValue<number>> = [
  { label: 'January', value: 0 },
  { label: 'February', value: 1 },
  { label: 'March', value: 2 },
  { label: 'April', value: 3 },
  { label: 'May', value: 4 },
  { label: 'June', value: 5 },
  { label: 'July', value: 6 },
  { label: 'August', value: 7 },
  { label: 'September', value: 8 },
  { label: 'October', value: 9 },
  { label: 'November', value: 10 },
  { label: 'December', value: 11 },
];

if (Number(no_of_hours_per_shift) <= 0) {
  total_no_of_shifts_in_a_day = 0;
}
if (Number(total_no_of_shifts_in_a_day) > 0) {
  if (Number(total_no_of_shifts_in_a_day) > Number(max_no_of_shifts_allowed)) {
    total_no_of_shifts_in_a_day = max_no_of_shifts_allowed;
  }

  let shiftDurationFrom = shift_start_time;
  let setShiftDurationTo = 0;
  let formatShiftDurationFrom = '';
  let formatsetShiftDurationTo = '';
  for (var i = 0; i < total_no_of_shifts_in_a_day; i++) {
    if (i > 0) {
      shiftDurationFrom = Number(setShiftDurationTo);
    }

    setShiftDurationTo = Number(shiftDurationFrom) + Number(no_of_hours_per_shift);
    formatShiftDurationFrom = '+' + shiftDurationFrom + 'h';
    formatsetShiftDurationTo = '+' + setShiftDurationTo + 'h';

    quickOptions.push({
      from: 'now/d' + formatShiftDurationFrom,
      to: 'now-1d/d' + formatsetShiftDurationTo,
      display: 'Shift ' + constABBShifts[i],
    });
  }
}

/* <!--abb-whitelisted--> */
export let constABBIcon = {
    'qrcode': 'abb/qr-code',
    'errordelete': 'abb/error-circle-1',
    'user': 'abb/user',
    'profile': 'abb/user-in-circle',
    'help': 'abb/help-circle-1',
    'plus': 'abb/plus',
    'plusincircle': 'abb/plus-in-circle',
    'pause': 'abb/qr-code',
    'alarmbell': 'abb/alarm-bell',
    'minus': 'abb/minus',
    'wrench': 'abb/wrench',
    'settings': 'abb/settings',
    'search': 'abb/search',
    'users': 'abb/user-in-circle',
    'teams': 'abb/group',
    'upgrading': 'abb/lock-closed',
    'folder': 'abb/folder-new',
    'folderclose': 'abb/folder',
    'folderopen': 'abb/folder-open',
    'import': 'abb/download',
    'home': 'abb/home',
    'snapshots': 'abb/camera',
    'org_settings': 'abb/controls-and-settings',
    'apikeys': 'abb/key',
    'close': 'abb/close',
    'barchart': 'abb/bar-chart',
    'lockclosed': 'abb/lock-closed',
    'screen': 'abb/screen',
    'synchronize': 'abb/synchronize',
    'time': 'abb/time',
    'zoomout': 'abb/zoom-out',
    'save': 'abb/save',
    'explore': 'abb/map',
    'filter': 'abb/filter',
    'sortalphadown': 'abb/abb_sort-alphabet-down',
    'moon': 'abb/moon',
    'sun': 'abb/sun-2',
    'star': 'abb/star',
    'leftarrow': 'abb/left-arrow',
    'rightarrow': 'abb/right-arrow',
    'informationcircle': 'abb/information-circle-1',
    'briefcase': 'abb/briefcase',
    'tdown': 'abb/triangle-down',
    'tup': 'abb/triangle-up',
    'hourglass': 'abb/hour-glass',
    'keyboard': 'abb/software-resources',
    'bars': 'abb/bar-chart',
    'panel': 'abb/document',
    'link': 'abb/link',
    'checkmark': 'abb/check-mark',
    'trash': 'abb/trash',
};

export let constABBImg = {
    'logo': 'public/img/abb/abb_icon.svg',
    'transparent': 'public/img/transparent.png',
    'avatar': 'public/img/abb/abb_avatar.svg',
    'dark': 'public/img/dark.png',
    'light': 'public/img/login_background_light.svg',
};


export let constABBShifts = [
    'A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z'
];

/*Maximum number of shifts allowed at a stretch.
Not more than value set under max_no_of_shifts_allowed. 
If more than max_no_of_shifts_allowed is set, then it is 
reset back to max_no_shifts_allowed value.
*/
export let max_no_of_shifts_allowed = 27;

/*Path of images location for dashboard app links menu icons*/
export let dashboardlinks_img_folder = 'public/abb-conf/dashboard-app-links/img/';
export let app_defaults = {
    'header_dark': 'apps_dark.svg',
    'header_light': 'apps_light.svg',
    'default_dark': 'link_dark.svg',
    'default_light': 'link_light.svg'
};



/* <!--abb-whitelisted--> */
import { DefaultTheme } from './default';
/*import {useTheme} from '@grafana/ui';*/
/*const theme = useTheme();*/

const basicColors = {
    red: '#F03040',
    black: '#1F1F1F',
    black1: '#0F0F0F',
    black2: '#000000',
    white: '#FFFFFF',
}

const basicColorsDark = {
    darkGray3: '#848484',
};

const basicColorsLight = {
    darkGray3: '#BABABA',
};

export let ABBTheme = ABBUseTheme('dark');

function ABBUseTheme(ctheme: string) {

    if (ctheme === 'light') {
        const ABBLightTheme = {
            isDark: false,
            isLight: true,
            name: 'ABB Light',
            palette: {
                ...basicColors,
                ...basicColorsLight
            },
            font: {
                ...DefaultTheme.font,
            },
            margin: {
                ...DefaultTheme.margin,
            },
        };
        return ABBLightTheme;
    } else {
        const ABBDarkTheme = {
            isDark: true,
            isLight: false,
            name: 'ABB Dark',
            palette: {
                ...basicColors,
                ...basicColorsDark,
            },
            font: {
                ...DefaultTheme.font,
            },
            margin: {
                ...DefaultTheme.margin,
            },
        };
        return ABBDarkTheme;
    }

}

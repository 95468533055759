/* <!--abb-whitelisted--> */
import { merge } from 'lodash';
import { abbBasicColors } from '../../../grafana-ui/src/themes/abbDefault';
import { alpha, darken, emphasize, getContrastRatio, lighten } from './colorManipulator';
import { palette } from './palette';
import { DeepPartial, ThemeRichColor } from './types';

/** @internal */
export type ThemeColorsMode = 'light' | 'dark';

/** @internal */
export interface ThemeColorsBase<TColor> {
  mode: ThemeColorsMode;

  primary: TColor;
  secondary: TColor;
  info: TColor;
  error: TColor;
  success: TColor;
  warning: TColor;
  primaryblack: TColor;
  primarylogin: TColor;
  primaryloginlink: TColor;
  destructive: TColor;
  destructivered: TColor;
  primaryblue: TColor;
  autoFillBorder: TColor;
  text: {
    primary: string;
    secondary: string;
    disabled: string;
    link: string;
    /** Used for auto white or dark text on colored backgrounds */
    maxContrast: string;
    textWeak: string;
    neutral: string;
  };

  background: {
    /** Dashboard and body background */
    canvas: string;
    /** Primary content pane background (panels etc) */
    primary: string;
    /** Cards and elements that need to stand out on the primary background */
    secondary: string;
    /** dashboard headers */
    header: string;
    /** Dashboard and body background - as per new abb color */
    canvasnew: string;
  };

  border: {
    weak: string;
    medium: string;
    strong: string;
    weakcontrast: string;
    tablerow: string;
  };

  gradients: {
    brandVertical: string;
    brandHorizontal: string;
  };

  action: {
    /** Used for selected menu item / select option */
    selected: string;
    /** Used for hovered menu item / select option */
    hover: string;
    /** Used for button/colored background hover opacity */
    hoverOpacity: number;
    /** Used focused menu item / select option */
    focus: string;
    /** Used for disabled buttons and inputs */
    disabledBackground: string;
    /** Disabled text */
    disabledText: string;
    /** Disablerd opacity */
    disabledOpacity: number;
  };

  hoverFactor: number;
  contrastThreshold: number;
  tonalOffset: number;

  form: {
    label: string;
    description: string;
    inputBg: string;
    inputBgDisabled: string;
    inputBorder: string;
    inputBorderHover: string;
    inputBorderActive: string;
    inputBorderInvalid: string;
    inputPlaceholderText: string;
    inputText: string;
    inputDisabledText: string;
    focusOutline: string;
    validationMessageText: string;
    validationMessageBg: string;
    switchBg: string;
    switchBgActive: string;
    switchBgHover: string;
    switchBgActiveHover: string;
    switchBgDisabled: string;
    switchDot: string;
    checkboxBgChecked: string;
    checkboxBgCheckedHover: string;
    checkboxCheckmark: string;
    dropdownOptionHoverBg: string;
  };
}

export interface ThemeHoverStrengh {}

/** @beta */
export interface ThemeColors extends ThemeColorsBase<ThemeRichColor> {
  /** Returns a text color for the background */
  getContrastText(background: string, threshold?: number): string;
  /* Brighten or darken a color by specified factor (0-1) */
  emphasize(color: string, amount?: number): string;
}

/** @internal */
export type ThemeColorsInput = DeepPartial<ThemeColorsBase<ThemeRichColor>>;

class DarkColors implements ThemeColorsBase<Partial<ThemeRichColor>> {
  mode: ThemeColorsMode = 'dark';

  // Used to get more white opacity colors
  whiteBase = '202, 202, 220';

  border = {
    weak: abbBasicColors.abbBlack1,
    medium: abbBasicColors.abbBlack,
    strong: abbBasicColors.abbDarkGray9,
    weakcontrast: abbBasicColors.abbWhite,
    tablerow: abbBasicColors.abbLightGray12,
  };

  text = {
    primary: palette.abbLightGray1,
    secondary: palette.abbLightGray2,
    disabled: palette.abbLightGray12,
    link: abbBasicColors.abbBlue, // palette.blueDarkText,
    maxContrast: palette.abbLightGray1,
    textWeak: palette.abbLightGray5,
    neutral: abbBasicColors.abbBlack1,
  };

  primary = {
    main: abbBasicColors.abbBlue,
    text: abbBasicColors.abbWhite,
    border: palette.blueDarkText,
  };

  secondary = {
  /*  main: `rgba(${this.whiteBase}, 0.1)`,
    shade: `rgba(${this.whiteBase}, 0.15)`,
    transparent: `rgba(${this.whiteBase}, 0.08)`,
    text: this.text.primary,
    contrastText: `rgb(${this.whiteBase})`,
    border: this.border.strong,*/
    main: `rgba(${this.whiteBase}, 0.1)`,
    shade: `rgba(${this.whiteBase}, 0.15)`,
    transparent: `rgba(${this.whiteBase}, 0.08)`,
    text: this.text.primary,
    contrastText: `rgb(${this.whiteBase})`,
    border: this.border.strong,  
  };

  primaryblack = {
    main: abbBasicColors.abbBlack,
    text: abbBasicColors.abbWhite,
  };

  primarylogin = {
    main: abbBasicColors.abbBlack,
    text: abbBasicColors.abbWhite,
  };

  primaryloginlink = {
    main: abbBasicColors.abbWhite,
    text: abbBasicColors.abbBlack,
  };

  destructive = {
    main: abbBasicColors.abbBlue,
    text: abbBasicColors.abbBlue,
  };

  destructivered = {
    main: abbBasicColors.abbRed,
    text: abbBasicColors.abbRed,
  };

  primaryblue = {
    main: abbBasicColors.abbBlue,
    text: abbBasicColors.abbWhite,
  };

  autoFillBorder = {
    main: abbBasicColors.abbDarkGray1,
  };

  // info = this.primary;
  info = {
    main: abbBasicColors.abbBlue,
    text: abbBasicColors.abbWhite, //palette.redDarkText,
  };

  error = {
    main: abbBasicColors.abbRed, //palette.redDarkMain,
    text: abbBasicColors.abbWhite, //palette.redDarkText,
  };

  success = {
    main: abbBasicColors.abbGreen, //palette.greenDarkMain,
    text: abbBasicColors.abbWhite, //palette.greenDarkText,
  };

  warning = {
    main: abbBasicColors.abbOrange, //palette.orangeDarkMain,
    text: abbBasicColors.abbWhite, //palette.orangeDarkText,
  };

  background = {
    canvas: abbBasicColors.abbBlack1, //palette.gray05,
    primary: abbBasicColors.abbBlack, //palette.gray10,
    secondary: abbBasicColors.abbDarkGray4, //palette.gray15,
    header: abbBasicColors.abbBlack,
    canvasnew: abbBasicColors.abbDarkGray11,
  };

  action = {
    hover: `rgba(${this.whiteBase}, 0.08)`,
    selected: `rgba(${this.whiteBase}, 0.12)`,
    focus: `rgba(${this.whiteBase}, 0.16)`,
    hoverOpacity: 0.08,
    disabledText: this.text.disabled,
    disabledBackground: this.border.strong,
    disabledOpacity: 0.38,
  };

  gradients = {
    brandHorizontal: ' linear-gradient(270deg, #36F 0%, #36F 100%);',
    brandVertical: 'linear-gradient(0.01deg, #36F 0.01%, #36F 99.99%);',
  };

  contrastThreshold = 3;
  hoverFactor = 0.03;
  tonalOffset = 0.15;
  
  form = {
    label: abbBasicColors.abbDarkGray3,
    description: abbBasicColors.abbLightGray5,
    inputBg: abbBasicColors.abbBlack,
    inputBgDisabled: abbBasicColors.abbLightGray7,
    inputBorder: abbBasicColors.abbDarkGray2,
    inputBorderHover: abbBasicColors.abbDarkGray2,
    inputBorderActive: abbBasicColors.abbDarkGray2,
    inputBorderInvalid: abbBasicColors.abbBlue,
    inputPlaceholderText: abbBasicColors.abbLightGray1Opacity40,
    inputText: abbBasicColors.abbLightGray1,
    inputDisabledText: abbBasicColors.abbLightGray4,
    focusOutline: abbBasicColors.abbDarkGray2,
    validationMessageText: abbBasicColors.abbWhite,
    validationMessageBg: abbBasicColors.abbRed,
    switchBg: abbBasicColors.abbDarkGray2,
    switchBgActive: abbBasicColors.abbBlue,
    switchBgHover: abbBasicColors.abbDarkGray2,
    switchBgActiveHover: abbBasicColors.abbBlue,
    switchBgDisabled: abbBasicColors.abbDarkGray2,
    switchDot: abbBasicColors.abbWhite,
    checkboxBgChecked: abbBasicColors.abbBlue,
    checkboxBgCheckedHover: abbBasicColors.abbBlue,
    checkboxCheckmark: abbBasicColors.abbWhite,
    dropdownOptionHoverBg: abbBasicColors.abbDarkGray4,
  };
}

class LightColors implements ThemeColorsBase<Partial<ThemeRichColor>> {
  mode: ThemeColorsMode = 'light';

  blackBase = '36, 41, 46';

  primary = {
    main: abbBasicColors.abbBlue,
    text: abbBasicColors.abbBlack, //palette.blueLightText,
    border: abbBasicColors.abbBlue, //palette.blueLightText,
  };

  text = {
    primary: abbBasicColors.abbDarkGray1, // `rgba(${this.blackBase}, 1)`
    secondary: abbBasicColors.abbDarkGray10, // `rgba(${this.blackBase}, 0.75)`
    disabled: abbBasicColors.abbDarkGray4, // `rgba(${this.blackBase}, 0.50)`
    link: abbBasicColors.abbBlue, // this.primary.text,
    maxContrast: palette.black,
    textWeak: palette.abbLightGray5,
    neutral: abbBasicColors.abbBlack1,
  };

  border = {
    weak: abbBasicColors.abbWhite, //`rgba(${this.blackBase}, 0.12)`,
    medium: abbBasicColors.abbLightGray1, //`rgba(${this.blackBase}, 0.30)`,
    strong: abbBasicColors.abbLightGray4, //`rgba(${this.blackBase}, 0.40)`,
    weakcontrast: abbBasicColors.abbBlack,
    tablerow: abbBasicColors.abbLightGray4,
  };

  secondary = {
    main: `rgba(${this.blackBase}, 0.11)`,
    shade: `rgba(${this.blackBase}, 0.16)`,
    contrastText: `rgba(${this.blackBase},  1)`,
    text: this.text.primary,
    border: this.border.strong,
  };

  primaryblack = {
    main: abbBasicColors.abbBlack,
    text: abbBasicColors.abbWhite,
  };

  primarylogin = {
    main: abbBasicColors.abbWhite,
    text: abbBasicColors.abbBlack,
  };

  primaryloginlink = {
    main: abbBasicColors.abbBlack,
    text: abbBasicColors.abbWhite,
  };

  destructive = {
    main: abbBasicColors.abbBlue,
    text: abbBasicColors.abbBlue,
  };

  destructivered = {
    main: abbBasicColors.abbRed,
    text: abbBasicColors.abbRed,
  };

  primaryblue = {
    main: abbBasicColors.abbBlue,
    text: abbBasicColors.abbWhite,
  };

  autoFillBorder = {
    main: abbBasicColors.abbLightGray1,
  };

  info = {
    main: abbBasicColors.abbBlue, // palette.blueLightMain,
    text: palette.white,
  };

  error = {
    main: abbBasicColors.abbRed, //palette.redLightMain
    text: abbBasicColors.abbWhite,
    border: abbBasicColors.abbRed1,
  };

  success = {
    main: abbBasicColors.abbGreen, // palette.greenLightMain,
    text: abbBasicColors.abbWhite, // palette.greenLightText
  };

  warning = {
    main: abbBasicColors.abbOrange, // palette.orangeLightMain
    text: abbBasicColors.abbWhite, // palette.orangeLightText
  };

  background = {
    canvas: abbBasicColors.abbLightGray, //palette.gray90,
    primary: abbBasicColors.abbWhite, //palette.white,
    secondary: abbBasicColors.abbLightGray2, //palette.gray100,
    header: abbBasicColors.abbLightGray10,
    canvasnew: abbBasicColors.abbLightGray,
  };

  action = {
    hover: `rgba(${this.blackBase}, 0.04)`,
    selected: `rgba(${this.blackBase}, 0.08)`,
    hoverOpacity: 0.08,
    focus: `rgba(${this.blackBase}, 0.12)`,
    disabledBackground: `rgba(${this.blackBase}, 0.07)`,
    disabledText: this.text.disabled,
    disabledOpacity: 0.38,
  };

  gradients = {
    brandHorizontal: 'linear-gradient(90deg, #36F 0%, #36F 100%);',
    brandVertical: 'linear-gradient(0.01deg, #36F -31.2%, #36F 113.07%);',
  };

  contrastThreshold = 3;
  hoverFactor = 0.03;
  tonalOffset = 0.2;
  form = {
    label: abbBasicColors.abbDarkGray2,
    description: abbBasicColors.abbLightGray5,
    inputBg: abbBasicColors.abbWhite,
    inputBgDisabled: abbBasicColors.abbLightGray8,
    inputBorder: abbBasicColors.abbDarkGray1,
    inputBorderHover: abbBasicColors.abbDarkGray1,
    inputBorderActive: abbBasicColors.abbDarkGray1,
    inputBorderInvalid: abbBasicColors.abbRed,
    inputPlaceholderText: abbBasicColors.abbDarkGray1,
    inputText: abbBasicColors.abbBlack,
    inputDisabledText: abbBasicColors.abbLightGray4,
    focusOutline: abbBasicColors.abbLightGray,
    validationMessageText: abbBasicColors.abbWhite,
    validationMessageBg: abbBasicColors.abbRed,
    switchBg: abbBasicColors.abbDarkGray2,
    switchBgActive: abbBasicColors.abbBlue,
    switchBgHover: abbBasicColors.abbDarkGray2,
    switchBgActiveHover: abbBasicColors.abbBlue,
    switchBgDisabled: abbBasicColors.abbDarkGray2,
    switchDot: abbBasicColors.abbWhite,
    checkboxBgChecked: abbBasicColors.abbBlue,
    checkboxBgCheckedHover: abbBasicColors.abbBlue,
    checkboxCheckmark: abbBasicColors.abbWhite,
    dropdownOptionHoverBg: abbBasicColors.abbLightGray1,
  };
}

export function createColors(colors: ThemeColorsInput): ThemeColors {
  const dark = new DarkColors();
  const light = new LightColors();
  const base = (colors.mode ?? 'dark') === 'dark' ? dark : light;
  const {
    primary = base.primary,
    secondary = base.secondary,
    info = base.info,
    warning = base.warning,
    success = base.success,
    error = base.error,
    primaryblack = base.primaryblack,
    primarylogin = base.primarylogin,
    primaryloginlink = base.primaryloginlink,
    destructive = base.destructive,
    destructivered = base.destructivered,
    primaryblue = base.primaryblue,
    autoFillBorder = base.autoFillBorder,
    tonalOffset = base.tonalOffset,
    hoverFactor = base.hoverFactor,
    contrastThreshold = base.contrastThreshold,
    ...other
  } = colors;

  function getContrastText(background: string, threshold: number = contrastThreshold) {
    const contrastText =
      getContrastRatio(dark.text.maxContrast, background, base.background.primary) >= threshold
        ? dark.text.maxContrast
        : light.text.maxContrast;
    // todo, need color framework
    return contrastText;
  }

  const getRichColor = ({ color, name }: GetRichColorProps): ThemeRichColor => {
    color = { ...color, name };
    if (!color.main) {
      throw new Error(`Missing main color for ${name}`);
    }
    if (!color.text) {
      color.text = color.main;
    }
    if (!color.border) {
      color.border = color.text;
    }
    if (!color.shade) {
      color.shade = base.mode === 'light' ? darken(color.main, tonalOffset) : lighten(color.main, tonalOffset);
    }
    if (!color.transparent) {
      color.transparent = base.mode === 'light' ? alpha(color.main, 0.08) : alpha(color.main, 0.15);
    }
    if (!color.contrastText) {
      color.contrastText = getContrastText(color.main);
    }
    return color as ThemeRichColor;
  };

  return merge(
    {
      ...base,
      primary: getRichColor({ color: primary, name: 'primary' }),
      secondary: getRichColor({ color: secondary, name: 'secondary' }),
      info: getRichColor({ color: info, name: 'info' }),
      error: getRichColor({ color: error, name: 'error' }),
      success: getRichColor({ color: success, name: 'success' }),
      warning: getRichColor({ color: warning, name: 'warning' }),
      primaryblack: getRichColor({ color: primaryblack, name: 'primaryblack' }),
      primarylogin: getRichColor({ color: primarylogin, name: 'primarylogin' }),
      primaryloginlink: getRichColor({ color: primaryloginlink, name: 'primaryloginlink' }),
      destructive: getRichColor({ color: destructive, name: 'destructive' }),
      destructivered: getRichColor({ color: destructivered, name: 'destructivered' }),
      primaryblue: getRichColor({ color: primaryblue, name: 'primaryblue' }),
      autoFillBorder: getRichColor({ color: autoFillBorder, name: 'autoFillBorder' }),
      getContrastText,
      emphasize: (color: string, factor?: number) => {
        return emphasize(color, factor ?? hoverFactor);
      },
    },
    other
  );
}
interface GetRichColorProps {
  color: Partial<ThemeRichColor>;
  name: string;
}

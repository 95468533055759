/* <!--abb-whitelisted--> */
export const abbBasicColors = {
  abbDarkGray1: '#9F9F9F',
  abbDarkGray2: '#686868',
  abbDarkGray3: '#848484',
  abbDarkGray4: '#333333',
  abbDarkGray5: '#333333',
  abbDarkGray6: '#2C2C2C',
  abbDarkGray7: '#363636',
  abbDarkGray8: '#BABABA',
  abbDarkGray9: '#707070',
  abbDarkGray10: '#4D4D4D',
  abbDarkGray11: '#262626',
  abbRed: '#F03040',
  abbRed1: '#FF7300',
  abbYellow: '#FFD800',
  abbLightGray: '#EBEBEB',
  abbLightGray1: '#DBDBDB',
  abbLightGray1Opacity40: 'rgba(219, 219, 219, 0.4)',
  abbLightGray2: '#EEEEEE',
  abbLightGray3: '#202226', // gray15
  abbLightGray4: '#9FA7B3',
  abbLightGray5: '#7b8087',
  abbLightGray6: '#464C54',
  abbLightGray7: '#141619',
  abbLightGray8: '#E9EDF2', // gray95
  abbLightGray9: '#ACB6BF', //light.gray3
  abbLightGray10: '#DFDFDF',
  abbLightGray11: '#DDDDDD',
  abbLightGray12: '#686868',
  abbBlack: '#1F1F1F',
  abbBlack1: '#0F0F0F',
  abbBlack2: '#000000',
  abbWhite: '#FFFFFF',
  abbBlue: '#36F',
  abbBlueFog: '#C7d0D9',
  abbBlueDark: '#2A35FF',
  abbGreen: '#0CA919',
  abbPurple: '#FE85fc',
  abbOrange: '#EB7B18',
};

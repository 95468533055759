/* <!--abb-whitelisted--> */
import { cx, css, keyframes } from '@emotion/css';
import { ABBTheme } from 'abb/ABBConfig';

import React, { useEffect, useState } from 'react';

import { GrafanaTheme2 } from '@grafana/data';
import { useStyles2, styleMixins } from '@grafana/ui';

import { Branding } from '../Branding/Branding';
import { BrandingSettings } from '../Branding/types';
//import { Footer } from '../Footer/Footer';

interface InnerBoxProps {
  enterAnimation?: boolean;
}
export const InnerBox = ({ children, enterAnimation = true }: React.PropsWithChildren<InnerBoxProps>) => {
  const loginStyles = useStyles2(getLoginStyles);
  return <div className={cx(loginStyles.loginInnerBox, enterAnimation && loginStyles.enterAnimation)}>{children}</div>;
};

export interface LoginLayoutProps {
  /** Custom branding settings that can be used e.g. for previewing the Login page changes */
  branding?: BrandingSettings;
}

export const LoginLayout = ({ children, branding }: React.PropsWithChildren<LoginLayoutProps>) => {
  const loginStyles = useStyles2(getLoginStyles);
  const [startAnim, setStartAnim] = useState(false);
  const subTitle = branding?.loginSubtitle ?? Branding.GetLoginSubTitle();
  const loginTitle = branding?.loginTitle ?? Branding.LoginTitle;
  const loginBoxBackground = branding?.loginBoxBackground || Branding.LoginBoxBackground();
  const loginLogo = branding?.loginLogo;

  useEffect(() => setStartAnim(true), []);
  
  const lawText = 'This is a private system. Do not attempt to logon unless you are an authorized user. Any authorized or unauthorized access and use may be monitored and can result in  criminal or civil prosecution under applicable law.';
  const copyright = 'All rights reserved 2023';
  return (
    <Branding.LoginBackground
      className={cx(loginStyles.container, startAnim && loginStyles.loginAnim, branding?.loginBackground)}
    >
      <div className={cx(loginStyles.loginContent, loginBoxBackground, 'login-content-box')}>
        <div className={loginStyles.loginLogoWrapper}>
          <Branding.LoginLogo className={loginStyles.loginLogo} logo={loginLogo} />
          <div className={loginStyles.titleWrapper}>
            {subTitle && <h1 className={loginStyles.redborder} />}
            {subTitle && <h1 className={loginStyles.subTitle}>{subTitle}</h1>}
            <h2 className={loginStyles.mainTitle}>{loginTitle}</h2> 
          </div>
        </div>
        <div className={loginStyles.loginOuterBox}>{children}</div>
      </div>
      {/* <Footer /> */}
     {/*}<p className={loginStyles.copyright}>{copyright}</p>{*/}
      <p className={loginStyles.lawText}>{copyright}<br/>{lawText}</p>
      {/*}{branding?.hideFooter ? <></> : <Footer customLinks={branding?.footerLinks} />}{*/}
    </Branding.LoginBackground>
  );
};

const flyInAnimation = keyframes`
from{
  opacity: 0;
  transform: translate(-60px, 0px);
}

to{
  opacity: 1;
  transform: translate(0px, 0px);
}`;

export const getLoginStyles = (theme: GrafanaTheme2) => {
  return {
    container: css({
      minHeight: '100%',
      backgroundPosition: 'center',
      backgroundRepeat: 'no-repeat',
      minWidth: '100%',
      marginLeft: 0,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
    }),
    loginAnim: css`
      &:before {
        opacity: 1;
      }

      .login-content-box {
        opacity: 1;
      }
    `,
    submitButton: css`
      justify-content: center;
      width: 100%;
    `,
    loginLogo: css`
      width: 100%;
      max-width: 60px;
      margin-bottom: 15px;

      @media ${styleMixins.mediaUp(theme.v1.breakpoints.sm)} {
        max-width: 100px;
      }
    `,
    loginLogoWrapper: css`
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      padding: ${theme.spacing(3)};
    `,
    titleWrapper: css`
      text-align: center;
    `,
    subTitle: css`
      font-size: 22px;
      font-weight: ${theme.typography.fontWeightBold};
      color: ${ABBTheme.palette.black1};
      @media ${styleMixins.mediaUp(theme.v1.breakpoints.sm)} {
        font-size: 32px;
      }
    `,
    mainTitle: css`
      font-size: 20px;
      color: ${ABBTheme.palette.black1};
      font-weight: ${theme.typography.fontWeightBold};
    `,
    loginContent: css`
      max-width: 478px;
      width: calc(100% - 2rem);
      display: flex;
      align-items: stretch;
      flex-direction: column;
      position: relative;
      justify-content: flex-start;
      z-index: 1;
      min-height: 320px;
      border-radius: ${theme.shape.borderRadius(4)};
      padding: ${theme.spacing(2, 0)};
      opacity: 0;
      transition: opacity 0.5s ease-in-out;

      @media ${styleMixins.mediaUp(theme.v1.breakpoints.sm)} {
        min-height: 320px;
        justify-content: center;
      }
    `,
    loginOuterBox: css`
      display: flex;
      overflow-y: hidden;
      align-items: center;
      justify-content: center;
    `,
    loginInnerBox: css`
      padding: ${theme.spacing(2)};

      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      flex-grow: 1;
      max-width: 415px;
      width: 100%;
      transform: translate(0px, 0px);
      transition: 0.25s ease;
    `,
    enterAnimation: css`
      animation: ${flyInAnimation} ease-out 0.2s;
    `,
    lawText: css`
    color: ${ABBTheme.palette.black1};
    font-size: ${theme.typography.size.sm};
    margin: auto;
    width: 86%;
    text-align: center;
    padding: 20px;
    `,
    copyright: css`
    color: ${ABBTheme.palette.black1};
    font-size: ${theme.typography.size.sm};
    text-align: right;
    width: 86%;
    padding-right: 10px;
    `,
    redborder: css`
    width: 40px;
    align-items: left;
    padding: 0px;
    border-bottom: solid 5px ${theme.colors.destructivered.text};
    clear:both;
    `

  };
};
